import React from 'react'
import { Link } from 'gatsby'
import { GatsbyImage } from 'gatsby-plugin-image'
import 'twin.macro'

import Layout from './Layout'
import DesignSystem from './DesignSystem'

const PostLink = ({ frontmatter, excerpt }) => {
  return (
    <article tw="mb-4">
      <Link
        to={`/articles/${frontmatter.slug}`}
        tw="flex flex-col items-stretch h-96 w-full relative text-white hover:text-yellow-500 transition-colors"
      >
        <GatsbyImage
          image={frontmatter.thumbnail.childImageSharp.gatsbyImageData}
          alt=""
          tw="h-full w-full"
        />
        <h2 tw="absolute bottom-0 bg-black bg-opacity-50 text-2xl p-4 pb-8 w-full text-center font-serif">
          {frontmatter.title}
        </h2>
        <div
          tw="absolute border-white border-2 border-opacity-80 pointer-events-none"
          style={{
            height: '95%',
            width: '95%',
            left: '2.5%',
            top: '2.5%',
          }}
        />
      </Link>
      <hr tw="border-b-2 w-16 border-orange mx-auto my-4" />
      {excerpt
        ?.split('\n')
        .filter(p => p)
        .map((p, i) => <DesignSystem.P key={i}>{p}</DesignSystem.P>) ?? null}
      <Link
        to={`/articles/${frontmatter.slug}`}
        tw="ml-4 border p-2 bg-gray-100 text-gray-800 font-light text-sm hover:bg-orange hover:text-white hover:border-orange transition-colors"
      >
        Read on
      </Link>
    </article>
  )
}

const ArticleListPage = ({ data, preList = null, afterList = null }) => {
  return (
    <Layout>
      <GatsbyImage
        image={data.banner.childImageSharp.gatsbyImageData}
        alt="Hawaii Travel Blog, Experiences, Reviews, Recipes and Tips | shaka.zone"
      />
      {preList}
      <div tw="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4 m-4">
        {data.allMdx.edges.map(({ node }) => (
          <PostLink
            frontmatter={node.frontmatter}
            excerpt={node.excerpt}
            key={node.frontmatter.slug}
          />
        ))}
      </div>
      {afterList}
    </Layout>
  )
}

export default ArticleListPage
