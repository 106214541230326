import React from 'react'
import { graphql } from 'gatsby'
import 'twin.macro'

import SEO from '../components/SEO'
import ArticleListPage from '../components/ArticleListPage'
import DesignSystem from '../components/DesignSystem'

const Reviews = ({ data }) => {
  return (
    <>
      <SEO />
      <ArticleListPage
        data={data}
        preList={
          <div tw="w-full flex justify-center">
            <DesignSystem.H1>Reviews</DesignSystem.H1>
          </div>
        }
      />
    </>
  )
}
export default Reviews

export const query = graphql`
  query {
    allMdx(
      limit: 10
      sort: { fields: frontmatter___published, order: DESC }
      filter: { frontmatter: { categories: { in: "reviews" } } }
    ) {
      edges {
        node {
          frontmatter {
            title
            slug
            published
            summary
            thumbnail {
              childImageSharp {
                gatsbyImageData(layout: FULL_WIDTH)
              }
            }
          }
          excerpt(pruneLength: 250)
        }
      }
    }
    banner: file(relativePath: { glob: "banner.png" }) {
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH)
      }
    }
  }
`
